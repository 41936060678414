/* Add these styles to your existing App.css or create a new CSS file */

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    color: black;
    overflow-y: auto;
  }
  
  .App {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  /* Styles for the chat container */
  .MainContainer {
    background-color: #3d3d3d;
    opacity: 0.5;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .ChatContainer {
    height: 100%;
  }
  
  /* Styles for the message list */
  .MessageList {
    max-height: calc(100% - 40px); /* Adjust max height based on your design */
    overflow-y: auto;
  }
  
  /* Styles for individual messages */
  .Message {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  /* Styles for the message input */
  .MessageInput {
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  /* Styles for the typing indicator */
  .TypingIndicator {
    padding: 5px;
    font-style: italic;
    color: #888;
  }
  
  /* Styles for the send button */
  .SendButton {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .SendButton:hover {
    background-color: #45a049;
  }
  
  /* Styles for the input field */
  .InputField {
    width: calc(100% - 80px); /* Adjust width based on your design */
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  
.rectangle-button {
    width: 80em; /* Takes up 75% of the screen width */
    margin: 3em; /* Centers the element horizontally */
    padding: 20px; /* Adjust padding as needed */
    border: 4px solid #000000; /* Black border, adjust color as needed */
    background-size: cover; /* Ensures the image covers the button without stretching */
    background-position: center; /* Centers the background image */
    background-repeat: no-repeat; /* Ensures the image doesn't repeat */
    border-radius: 15px; /* Curved corners */
    border: 2px solid #fff; /* White border */
    cursor: pointer;
    position: relative;
    overflow: hidden; /* Ensures content stays within the button bounds */
    display: flex;
    align-items: center; /* Align text vertically in the center */
    justify-content: center; /* Center text horizontally */
    padding: 10px; /* Padding around the text */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
    height: 200px; /* Fixed height of the button */
    text-align: center; /* Centers the text inside the rectangle */
    transition: filter 0.3s ease; /* Smooth transition for filter effect */

  }
  
  .rectangle-button .button-text {
    color: white; /* White text */
    font-weight: bold; /* Bold text */
    font-size: 50px; /* Set font size to 50px, half of the 200px height */
    text-align: left; /* Center-align text */
    padding: 10px;
    width: 100%; /* Ensure the text spans the full width */
  }
  
  .rectangle-button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2); /* Darker overlay (40% opacity) on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}


  .rectangle-button:hover::before {

    background-color: rgba(0, 0, 0, 0); /* Slight dark overlay (20% opacity) */
}

body {
  background-image: url('../../../public/BackgroundNeoTokyo.webp'); /* Path to your background image */
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the background image */
  background-attachment: fixed; /* Makes the background image stay fixed while scrolling */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  cursor: default; /* Default cursor */
  position: relative; /* Needed for the overlay to position correctly */
}

body::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.74); /* Adjust the color and opacity */
  pointer-events: none; /* Ensures the overlay doesn't block mouse events */
  z-index: 0; /* Ensure the overlay is behind all content */
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh; /* Full viewport height */
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
  overflow: auto;
  position: relative;
}

.modules-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px; /* Space between the rectangles */
  position: relative;
  z-index: 1;
}

/* Adjust Title text to ensure it aligns properly */
h1, p {
  color: #fff;
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  font-style:oblique;
  font-size: 1.5vw;
}

/* Update the Title component to make sure it doesn't stack vertically */
h1 {
  margin: 0;
  font-size: 3rem; /* Adjust as needed */
}



/* Additional button styles */
.customButton {
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  z-index: 3;
  position: relative;
}

.customButton:hover {
  background-color: #fff;
  color: #333;
}

.logo {
  height: 9rem;
  margin-bottom: 20px; /* Space between logo and title */
}

.returndiv{
  display: flex;
  justify-content: center;
}

.return{
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  z-index: 3;
  width: 10%;
  display: flex;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}

.return:hover {
  background-color: #ff6c6c;
  color: #000000;
}